
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import moment from 'moment';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { ElNotification } from 'element-plus';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Training-allowance-summary',
  components: {
    Datatable,
    ElNotification,
  },

  data() {
    return {
      moment: '' as any,
      institutes: [] as any,
      tranches: [] as any,
      associations: [] as any,
      courses: [] as any,
      batches: [] as any,

      TpartnerData: '',
      TranceData: '',
      Tinstitute: '',
      TbatchData: '',
      TcourseData: '',
      TpartnerLabel: '',
      TranceLabel: '',
      TinstituteLabel: '',
      TbatchLabel: '',
      TcourseLabel: '',

      entityInfos: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourse: [],
      tableData: [] as any,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
          selected: true,
        },
         {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_number',
          sortable: true,
          selected: true,
        },

        {
          name: 'Enroll',
          key: 'enroll_trainees',
          sortable: true,
           selected: true,
        },
        {
          name: 'Female Enroll',
          key: 'female_enroll_trainees',
          sortable: true,
           selected: true,
        },
        {
          name: 'Assessment',
          key: 'assessment',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Female Assessment',
          key: 'female_assessment',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Certified',
          key: 'certified',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Female Certified',
          key: 'female_certified',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Placement',
          key: 'placement',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Female Placement',
          key: 'female_placement',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Placement (1M)',
          key: '1m_placement',
          sortable: true,
          selected: true,
        },
        {
          name: 'Placement (3M)',
          key: '3m_placement',
          sortable: true,
          selected: true,
        },
        {
          name: 'Placement (3M)(%)',
          key: '3m_placement_per',
          sortable: true,
          selected: true,
        },
      ] as any,

      st: [] as any,
      pdfHeader: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    this.moment = moment;
    await this.associationList();
    await this.getTranche();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity_id', this.TpartnerData);
      formData.set('tranche_id', this.TranceData);
      formData.set('institute_id', this.Tinstitute);
      formData.set('course_id', this.TcourseData);
      formData.set('batch_id', this.TbatchData);


      let data = `${this.VUE_APP_API_URL}/api/batch/placement_report_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity_id', this.TpartnerData);
      formData.set('tranche_id', this.TranceData);
      formData.set('institute_id', this.Tinstitute);
      formData.set('course_id', this.TcourseData);
      formData.set('batch_id', this.TbatchData);

      await ApiService.post('batch/placement_report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity_id', this.TpartnerData);
      formData.set('tranche_id', this.TranceData);
      formData.set('institute_id', this.Tinstitute);
      formData.set('course_id', this.TcourseData);
      formData.set('batch_id', this.TbatchData);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/batch/placement_report_export`,formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'BatchPlacementSummary.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    batchLabel(id) {
      let selectedBatch = this.batches.filter((data) => data.id == id);

      this.TbatchLabel = selectedBatch[0].batch_number;
    },
    trancheLabelChange(id) {
      let selectedtranche = this.tranches.filter((data) => data.id == id);

      this.TranceLabel = selectedtranche[0].label;
    },
    async getCourseList(id) {
      let selectedInstitute = this.institutes.filter((data) => data.id == id);
      this.TinstituteLabel = selectedInstitute[0].short_name;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'course/list?entity_id=' +
          entity_id +
          '&tranche=' +
          this.TranceData +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async trainingInstitute(id) {
      let selectedEntity = this.associations.filter((data) => data.id == id);

      this.TpartnerLabel = selectedEntity[0].entity_short_name;

      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'institute/list?entity_id=' +
          entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getBatchList(id) {
      let selectedCourse = this.courses.filter((data) => data.id == id);

      this.TcourseLabel = `${selectedCourse[0].code}| ${selectedCourse[0].course_name}| ${selectedCourse[0].month}| ${selectedCourse[0].hour}| ${selectedCourse[0].total_course_cost} `;
      let entity_id = this.TpartnerData;
      let institute_info_id = this.Tinstitute;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'batch/enroll_batch?entity_id=' +
          entity_id +
          '&tranche_id=' +
          this.TranceData +
          '&course_info_id=' +
          this.TcourseData +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    resetData() {
      this.TranceData = '';
      this.TpartnerData = '';
      this.Tinstitute = '';
      this.TcourseData = '';
      this.TbatchData = '';
      this.TranceLabel = '';
      this.TpartnerLabel = '';
      this.TinstituteLabel = '';
      this.TcourseLabel = '';
      this.TbatchLabel = '';
      this.tableData = [] as any;
      this.pdfHeader = [] as any;
      this.st = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async associationList() {
      let entity_id = this.TpartnerData;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },

    async generateAttendanceReport() {
      if (
        this.TpartnerData &&
        this.TranceData &&
        this.Tinstitute 
      ) {
        this.load = true;
        await ApiService.get(
          'batch/placement-report-batch?entity_id=' +
            this.TpartnerData +
            '&tranche_id=' +
            this.TranceData +
            '&institute_id=' +
            this.Tinstitute +
            '&course_id=' +
            this.TcourseData +
            '&batch_id=' +
            this.TbatchData
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
            this.load = false;
          })
          .then((response) => {
            console.log(response);
            this.load = false;
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select course first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    headerFilter() {
      this.showCourseNotice = true;
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },
});
